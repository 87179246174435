<template>
  <v-container fluid>
    <v-card color="white" height="auto" elevation="1" class="mb-4 pa-2">
      <v-row class="d-flex mt-4 mb-6 ml-1" v-if="!isViewOnly">
        <v-progress-linear
          v-if="uploadProgress"
          v-model="uploadProgress"
          height="25"
        >
          <strong>{{ Math.ceil(uploadProgress) }}%</strong>
        </v-progress-linear>
        <v-btn
          v-else
          outlined
          color="primary"
          class="mr-2 mb-2"
          elevation="1"
          @click="$refs.dialogUpload.dialog = true"
        >
          {{ $_strings.contract.label.UPLOAD_ATTACHMENT }}
        </v-btn>
      </v-row>
      <v-data-table
        :headers="displayedAttachmentHeader"
        :items="form.documents.filter((doc) => doc.documentType === 'ATTACHMENT' && !doc.isDeleted)"
        :items-per-page="100"
        hide-default-footer
        item-key="id"
      >
        <template v-slot:[`item.no`]=items>
          <span>{{ items.index + 1 }}</span>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                color="primary"
                dark
                icon
                @click="onButtonDownload(item)"
              >
                <v-icon>mdi-download</v-icon>
              </v-btn>
            </template>
            <span>{{ $_strings.contract.tooltips.DOWNLOAD }}</span>
          </v-tooltip>
          <v-tooltip bottom v-if="!isViewOnly">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                color="red"
                dark
                icon
                :loading="item.isLoading"
                @click="onDeleteItem(item)"
              >
                <v-icon>mdi-delete</v-icon>
              </v-btn>
            </template>
            <span>{{ $_strings.contract.tooltips.REMOVE }}</span>
          </v-tooltip>
        </template>
      </v-data-table>
      <v-row class="d-flex mt-8" v-if="!isViewOnly">
        <v-col cols="12" class="d-flex align-end justify-end">
          <v-btn
            color="primary"
            class="ml-4"
            large
            @click="submit()"
          >
            {{$_strings.contract.label.SAVE}}
          </v-btn>
        </v-col>
      </v-row>
    </v-card>
    <DialogContract
      ref="dialogUpload"
      label="Unggah Lampiran"
      @uploadFile="uploadFile"
    />
  </v-container>
</template>

<script>
import dayjs from 'dayjs';
import DialogContract from './Dialog/Upload.vue';

export default {
  props: {
    form: Object,
    isViewOnly: Boolean,
    newDocuments: Array,
  },
  components: {
    DialogContract,
  },
  data() {
    return {
      isLoading: false,
      dialogContract: false,
      uploadProgress: null,
      formDialog: {
        name: '',
        file: null,
      },
      attachmentHeaders: [
        { text: this.$_strings.contract.header.NO, value: 'no', sortable: false },
        { text: this.$_strings.contract.header.DOCUMENT_ATTACHMENT_NAME, value: 'documentName', sortable: false },
        { text: this.$_strings.contract.header.ACTION, value: 'actions', sortable: false },
      ],
    };
  },
  computed: {
    displayedAttachmentHeader() {
      return this.attachmentHeaders.map((i) => ({
        ...i,
        class: 'white--text primary',
      }));
    },
  },
  methods: {
    submit() {
      this.$emit('submitRevise');
    },
    onButtonDownload(item) {
      this.$_services.uploads.downloadFile(item.url);
    },
    async uploadFile(formAttachment, encodedFile) {
      this.$refs.dialogUpload.dialog = false;
      try {
        const { companyId, contractNo } = this.form;
        const fileName = `attachment_${dayjs().unix()}.${formAttachment.fileType}`;
        const filePath = `contract/${dayjs().get('year')}/${companyId}/${contractNo.split('/')[0]}/`;
        const self = this;
        const config = {
          onUploadProgress(progressEvent) {
            const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
            self.uploadProgress = percentCompleted;
          },
        };
        this.uploadProgress = 1;
        const result = await this.$_services.contract.uploadFile({ encodedFile, fileName, filePath }, config);
        const { url } = result.data;
        const data = {
          documentName: formAttachment.name,
          documentStatus: 'DRAFT',
          documentType: 'ATTACHMENT',
          url,
        };
        this.form.documents.push(data);
        this.newDocuments.push(data);
      } finally {
        this.uploadProgress = null;
      }
    },
    async onDeleteItem(item) {
      try {
        this.$set(item, 'isLoading', true);
        const payload = { url: item.url };
        await this.$_services.contract.deleteFile(payload);
        this.form.documents.splice(this.form.documents.indexOf(item), 1);
      } finally {
        this.$delete(item, 'isLoading');
      }
    },
  },
};
</script>
