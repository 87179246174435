<template>
  <v-container fluid>
    <v-container v-if="canApprove || canRevise || canReject" fluid>
      <h4>
        {{$_strings.companyRegistration.PAGE_NAME}}
      </h4>
      <v-form ref="form">
        <v-row>
          <v-col cols="12">
            <v-radio-group v-model="approvalAction" row>
              <v-radio
                v-if="canApprove"
                label="Menyetujui"
                :value="1"
                color="green"
              ></v-radio>
              <v-radio
                v-if="canRevise"
                label="Revisi"
                :value="2"
                color="orange"
              ></v-radio>
              <v-radio
                v-if="canReject"
                label="Menolak"
                :value="3"
                color="red"
              ></v-radio>
            </v-radio-group>
          </v-col>
        </v-row>
        <v-row class="pa-3">
          <v-col class="pt-0" cols="5" sm="2" md="1">
            <label class="ma-0" for="selectCompany">{{$_strings.common.NOTE}}</label>
          </v-col>
          <v-col cols="10" sm="9" md="11" class="d-flex justify-start align-center pa-0">
            <v-textarea
              no-resize
              outlined
              dense
              v-model="notes"
              rows="5"
              :counter="255"
              :rules="[(v) => rulesNotes(v, isRequiredNotes, 255, $_strings.common.NOTE)]"
            ></v-textarea>
          </v-col>
        </v-row>
      </v-form>
      <v-row class="pa-3 d-flex justify-end">
        <v-btn
          :disabled="!approvalAction"
          class="primary"
          @click="submitContract"
        >
          {{$_strings.common.SAVE}}
        </v-btn>
      </v-row>
    </v-container>
    <v-row>
      <v-col>
        <h4 class="mb-4">Riwayat Persetujuan</h4>
        <v-data-table
          :headers="displayedHeader"
          :items="itemsHistories"
          item-key="id"
          hide-default-footer
          :loading="isLoadingFetchHisoties"
          loading-text="Sedang memuat..."
          :items-per-page="100"
        >
          <template v-slot:[`item.no`]="{index}">
            {{index + 1}}
          </template>
          <template v-slot:[`item.actionDate`]="{item}">
            <span>{{ dateFormat(item.actionDate) }}</span>
            <p class="grey--text text--darken-2">{{ timeFormat(item.actionDate) }}</p>
          </template>
          <template v-slot:[`item.action`]="{item, index}">
            <span :class="getStyle(index)">{{formatAction(item.action)}}</span>
          </template>
          <template v-slot:[`item.notes`]="{item}">
            <pre><p class="ma-0">{{item.notes ? item.notes : '-'}}</p></pre>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import {
  statusActionWorkflow,
  dateFormat,
  timeFormat,
  rulesNotes,
} from '../../helper/commonHelpers';

export default {
  props: {
    workflowData: {
      type: Object,
      default: () => {},
    },
  },
  mounted() {
    this.handleData();
  },
  watch: {
    workflowData() {
      this.handleData();
    },
  },
  data() {
    return {
      canApprove: false,
      canReject: false,
      canRevise: false,
      workflowId: null,
      workflowDetailId: null,
      approvalAction: +this.$route.query.approvalAction || '',
      notes: null,
      itemsHistories: [],
      isLoadingFetchHisoties: false,
      headers: [
        {
          text: this.$_strings.companyDocuments.NO_TABLE_HEADER_LABEL,
          value: 'no',
        },
        {
          text: this.$_strings.common.DATE,
          value: 'actionDate',
        },
        {
          text: this.$_strings.common.NAME,
          value: 'stepName',
        },
        {
          text: this.$_strings.companyDocuments.ACTION_TABLE_HEADER_LABEL,
          value: 'action',
        },
        {
          text: this.$_strings.common.NOTE,
          value: 'notes',
        },
      ],
    };
  },
  methods: {
    rulesNotes,
    handleData() {
      if (this.workflowData.workflowId && !this.workflowId) {
        const {
          workflowId,
          workflowDetailId,
          canApprove,
          canReject,
          canRevise,
        } = this.workflowData;
        this.workflowId = workflowId;
        this.workflowDetailId = workflowDetailId;
        this.canApprove = canApprove;
        this.canReject = canReject;
        this.canRevise = canRevise;
        this.fetchHistoriesWorkflow();
      }
    },
    async fetchHistoriesWorkflow() {
      try {
        if (!this.workflowId) return;
        this.isLoadingFetchHisoties = true;
        const res = await this.$_services.mou.fetchHistoryWorkFlow(this.workflowId);
        this.itemsHistories = res.data.contents;
      } finally {
        this.isLoadingFetchHisoties = false;
      }
    },
    async submitContract() {
      const valid = this.$refs.form.validate();
      const msg = this.approvalAction === 2 ? 'revisi' : 'penolakan';
      if (!valid) return this.$dialog.notify.error(`Silahkan masukkan catatan ${msg}`);
      const { workflowId, workflowDetailId, notes } = this;
      const form = {
        workflowId,
        workflowDetailId,
        notes,
      };
      try {
        this.$root.$loading.show();
        const status = ['approve', 'revise', 'reject'];
        await this.$_services.contract.approveContract(form, status[this.approvalAction - 1]);
        this.$dialog.notify.success('Berhasil mengirim data');
        this.$router.go(-1);
      } finally {
        this.$root.$loading.hide();
      }
    },
    dateFormat,
    timeFormat,
    formatAction(action) {
      return statusActionWorkflow(action);
    },
    getStyle(index) {
      if (index % 2 === 0) {
        return 'blue--text text--lighten-1';
      }
      return 'orange--text text--lighten-1';
    },
  },
  computed: {
    isRequiredNotes() {
      const required = this.approvalAction === 2 || this.approvalAction === 3;
      return required;
    },
    displayedHeader() {
      return this.headers.map((i) => ({
        ...i,
        class: 'white--text primary text-capitalize',
        // cellClass: 'clickable',
      }));
    },
  },
};
</script>
