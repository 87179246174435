<template>
  <v-container fluid>
    <v-row class="mt-1">
      <v-col>
        <span class="font-weight-bold">
          {{$route.meta.headerTitle}}
        </span>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-tabs v-model="activeTab" background-color="transparent">
          <v-tab class="font-weight-bold text-capitalize">
            {{ $_strings.contract.PAGE_NAME_SHIPPER }}
          </v-tab>
          <v-tab class="font-weight-bold text-capitalize">
            {{ $_strings.contract.label.ATTACHMENT }}
          </v-tab>
          <v-tab class="font-weight-bold text-capitalize">
            {{ $_strings.approval.APPROVAL_PAGE_NAME }}
          </v-tab>
        </v-tabs>
      </v-col>
    </v-row>
    <v-tabs-items v-model="activeTab">
      <v-tab-item>
        <ContractInformation
          :form="form"
          ref="contractInformation"
          :isViewOnly="!(isRevise && fromPath === 'approval-inbox')"
          :newDocuments="newDocuments"
        />
      </v-tab-item>
      <v-tab-item>
        <Attachment
          :form="form"
          :isViewOnly="!(isRevise && fromPath === 'approval-inbox')"
          @submitRevise="submitRevise"
          :newDocuments="newDocuments"

        />
      </v-tab-item>
      <v-tab-item>
        <v-card>
          <Approval
            :workflowData="workflowData"
          />
        </v-card>
      </v-tab-item>
    </v-tabs-items>
  </v-container>
</template>

<script>
import Approval from '../components/ContractInProcess/Approval.vue';
import ContractInformation from '../components/ContractInProcess/ContractInformation.vue';
import Attachment from '../components/ContractInProcess/Attachment.vue';

export default {

  name: 'ContractInProcess',
  components: {
    Approval,
    ContractInformation,
    Attachment,
  },
  beforeDestroy() {
    if (this.newDocuments.length && !this.isSubmited) {
      const dataUrl = [];
      this.newDocuments.forEach((doc) => {
        dataUrl.push({
          url: doc.url,
        });
      });
      Promise.all(dataUrl.map((payload) => this.$_services.contract.deleteFile(payload)));
    }
  },
  data() {
    return {
      activeTab: +this.$route.query.tab || 0,
      isRevise: false,
      workflowData: {},
      newDocuments: [],
      form: {
        id: null,
        workflowId: null,
        companyId: null,
        companyName: '',
        companyType: '',
        mouId: 0,
        mouNo: '',
        contractType: '',
        contractFormatType: '',
        contractNo: '',
        periodStart: '',
        periodEnd: '',
        companyContractStatus: '',
        effectiveDatetime: '',
        isActive: null,
        approvalStatus: null,
        notes: null,
        createdBy: '',
        createdAt: '',
        isDeleted: null,
        documents: [],
      },
      isSubmited: false,
    };
  },
  computed: {
    fromPath() {
      // approval-inbox, approval-process, complete-approval
      const [,, path] = this.$route.path.split('/');
      return path;
    },
  },
  methods: {
    setData(data) {
      this.workflowData = data;
      this.isRevise = data.isRevise;
      this.form = data.data;
    },
    async fetchWorkFlowData() {
      const { id } = this.$route.params;
      this.$root.$loading.show();
      try {
        const result = await this.$_services.contract.getDetailWorkflowContract(id);
        const { data } = result;
        this.setData(data);
      } finally {
        this.$root.$loading.hide();
      }
    },
    async submitRevise() {
      const valid = this.$refs.contractInformation.$refs.contract_information_form.validate();
      if (!valid) {
        this.activeTab = 0;
        return;
      }
      const filterDocuments = this.form.documents.filter((doc) => !doc.isDeleted);
      const newForm = {
        ...this.form,
        documents: filterDocuments,
      };
      this.$root.$loading.show();
      try {
        const { workflowId, workflowDetailId } = this.workflowData;
        await this.$_services.contract.reviseContract(newForm, workflowId, workflowDetailId);
        this.isSubmited = true;
        this.$dialog.notify.success('Revisi berhasil dikirim');
        this.$router.go(-1);
      } finally {
        this.$root.$loading.hide();
      }
    },
  },
  created() {
    const { data } = this.$route.params;
    if (data) {
      this.setData(data);
    } else {
      this.fetchWorkFlowData();
    }
  },
};
</script>
