var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-card',{staticClass:"mb-4 pa-2",attrs:{"color":"white","height":"auto","elevation":"1"}},[(!_vm.isViewOnly)?_c('v-row',{staticClass:"d-flex mt-4 mb-6 ml-1"},[(_vm.uploadProgress)?_c('v-progress-linear',{attrs:{"height":"25"},model:{value:(_vm.uploadProgress),callback:function ($$v) {_vm.uploadProgress=$$v},expression:"uploadProgress"}},[_c('strong',[_vm._v(_vm._s(Math.ceil(_vm.uploadProgress))+"%")])]):_c('v-btn',{staticClass:"mr-2 mb-2",attrs:{"outlined":"","color":"primary","elevation":"1"},on:{"click":function($event){_vm.$refs.dialogUpload.dialog = true}}},[_vm._v(" "+_vm._s(_vm.$_strings.contract.label.UPLOAD_ATTACHMENT)+" ")])],1):_vm._e(),_c('v-data-table',{attrs:{"headers":_vm.displayedAttachmentHeader,"items":_vm.form.documents.filter(function (doc) { return doc.documentType === 'ATTACHMENT' && !doc.isDeleted; }),"items-per-page":100,"hide-default-footer":"","item-key":"id"},scopedSlots:_vm._u([{key:"item.no",fn:function(items){return [_c('span',[_vm._v(_vm._s(items.index + 1))])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"primary","dark":"","icon":""},on:{"click":function($event){return _vm.onButtonDownload(item)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-download")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$_strings.contract.tooltips.DOWNLOAD))])]),(!_vm.isViewOnly)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"red","dark":"","icon":"","loading":item.isLoading},on:{"click":function($event){return _vm.onDeleteItem(item)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-delete")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$_strings.contract.tooltips.REMOVE))])]):_vm._e()]}}],null,true)}),(!_vm.isViewOnly)?_c('v-row',{staticClass:"d-flex mt-8"},[_c('v-col',{staticClass:"d-flex align-end justify-end",attrs:{"cols":"12"}},[_c('v-btn',{staticClass:"ml-4",attrs:{"color":"primary","large":""},on:{"click":function($event){return _vm.submit()}}},[_vm._v(" "+_vm._s(_vm.$_strings.contract.label.SAVE)+" ")])],1)],1):_vm._e()],1),_c('DialogContract',{ref:"dialogUpload",attrs:{"label":"Unggah Lampiran"},on:{"uploadFile":_vm.uploadFile}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }