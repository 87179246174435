<template>
  <v-container fluid>
    <v-card color="white" height="auto" width="auto" elevation="1" class="mb-4 pa-6">
      <v-form ref="contract_information_form">
        <!-- ADDENDUM -->
        <v-row v-if="path === 'addendum-contract'" class="d-flex">
          <v-col cols="12" sm="4" md="3" class="d-flex align-center">
            <p>{{ $_strings.addendumContract.label.PARTNER_TYPE}}<span class="red--text">*</span></p>
          </v-col>
          <v-col cols="12" sm="auto">
            <v-radio-group
              v-model="form.companyType"
              hide-details
              disabled
            >
              <v-radio :key="1" value="SHIPPER" label="Shipper"></v-radio>
              <v-radio :key="2" value="TRANSPORTER" label="Rekanan 3PL"></v-radio>
            </v-radio-group>
          </v-col>
        </v-row>
        <!-- ============ -->
        <v-row v-else>
          <v-col class="d-flex align-center" cols="12" md="3" sm="4">
            <p>
              {{ $_strings.contract.label.CONTRACT_FORMAT }}<span class="red--text">*</span>
            </p>
          </v-col>
          <v-col cols="12" md="9" sm="4">
            <v-radio-group
              v-model="form.contractFormatType"
              disabled
            >
              <v-radio
                :value="'FASTLOG'"
                label="Kontrak Format Fastlog"
              ></v-radio>
              <v-radio
                :value="'COMPANY'"
                :label="form.companyType"
              ></v-radio>
            </v-radio-group>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="d-flex align-center" cols="12" md="3" sm="4">
            <p>
              {{ $_strings.contract.header.COMPANY_NAME }} <span class="red--text">*</span>
            </p>
          </v-col>
          <v-col cols="12" md="9" sm="8">
            <v-text-field
              v-model="form.companyName"
              aria-hidden="true"
              dense
              disabled
              height="30"
              outlined
              placeholder="Nama Perusahaan"
              required
            >
            </v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="d-flex align-center" cols="12" md="3" sm="4">
            <p>
              {{ $_strings.contract.header.CONTRACT_NUMBER }}<span class="red--text">*</span>
            </p>
          </v-col>
          <v-col cols="12" md="9" sm="8">
            <v-text-field
              v-model="form.contractNo"
              aria-hidden="true"
              dense
              :rules="[v => !!v || $_strings.messages.validation.REQUIRED($_strings.contract.header.CONTRACT_NUMBER)]"
              disabled
              height="30"
              outlined
              placeholder="Nomor Kontrak"
              required
            >
            </v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="d-flex align-center" cols="12" md="3" sm="4">
            <p>
              {{ $_strings.contract.label.MOU_NUMBER }}<span class="red--text">*</span>
            </p>
          </v-col>
          <v-col cols="12" md="9" sm="8">
            <v-text-field
              v-model="form.mouNo"
              aria-hidden="true"
              dense
              :rules="[v => !!v || $_strings.messages.validation.REQUIRED($_strings.contract.label.MOU_NUMBER)]"
              disabled
              height="30"
              outlined
              placeholder="Nomor MoU"
              required
            >
            </v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="d-flex align-center" cols="12" md="3" sm="4">
            <p>
              {{ $_strings.contract.label.EFFECTIVE_PERIOD }}<span class="red--text">*</span>
            </p>
          </v-col>
          <v-col cols="12" sm="6">
            <v-row>
              <v-col>
                <v-text-field
                  :value="dayjs(form.periodStart).format('DD-MM-YYYY')"
                  label="Date"
                  outlined
                  disabled
                ></v-text-field>
              </v-col>
              <v-col cols="auto">
                <p>{{ $_strings.contract.label.UNTIL}}<span class="red--text">*</span></p>
              </v-col>
              <v-col>
                <v-text-field
                  :value="dayjs(form.periodEnd).format('DD-MM-YYYY')"
                  label="Date"
                  outlined
                  disabled
                ></v-text-field>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <!-- ADENDUM -->
        <section v-if="path === 'addendum-contract'">
          <v-row>
            <v-col cols="12" sm="4" md="3">
              <span class="subtitle-1">{{ $_strings.addendumContract.label.ADENDUM_SIGNED }} <span class="red--text">*</span></span>
            </v-col>
            <v-col cols="12" md="4">
              <v-autocomplete
                dense
                outlined
                item-value="name"
                item-text="name"
                placeholder="Tempat"
                :items="cityList"
                v-model="form.addendumLocation"
                @input.native="($event) => fetchCityList($event.target.value)"
                :rules="rules.cityRule"
                :loading="isLoadingCityList"
                disabled
              >
              </v-autocomplete>
            </v-col>
            <v-col
              cols="auto"
            >
              <v-menu
                ref="signedDateShow"
                v-model="signedDateShow"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    prepend-inner-icon="mdi-calendar-month"
                    label="Tanggal"
                    single-line
                    outlined
                    dense
                    :value="form.addendumDatetime ? dayjs(form.addendumDatetime).format('DD/MM/YYYY') : null"
                    :rules="rules.dateRule"
                    aria-hidden="true"
                    readonly
                    disabled
                    persistent-hint
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="form.addendumDatetime"
                  no-title
                  @input="signedDateShow = false"
                  disabled
                  locale="id"
                ></v-date-picker>
              </v-menu>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" sm="4" md="3">
              <span class="subtitle-1">{{ $_strings.addendumContract.label.ADDENDUM_EFFECTIVE }} <span class="red--text">*</span></span>
            </v-col>
            <v-col
              cols="12"
              md="3"
            >
              <v-menu
                ref="effectiveDateShow"
                v-model="effectiveDateShow"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    prepend-inner-icon="mdi-calendar-month"
                    label="Tanggal"
                    single-line
                    outlined
                    dense
                    :value="form.effectiveDatetime ? dayjs(form.effectiveDatetime).format('DD/MM/YYYY') : null"
                    :rules="rules.dateRule"
                    aria-hidden="true"
                    readonly
                    disabled
                    persistent-hint
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="form.effectiveDatetime"
                  no-title
                  disabled
                  @input="effectiveDateShow = false"
                  :min="form.periodStart ? dayjs(form.periodStart).format('YYYY-MM-DD') : null"
                  :max="form.periodEnd ? dayjs(form.periodEnd).format('YYYY-MM-DD') : null"
                  locale="id"
                ></v-date-picker>
              </v-menu>
            </v-col>
          </v-row>
        </section>
      </v-form>
    </v-card>
    <v-card color="white" height="auto" elevation="1" class="mb-4 pa-2">
      <v-row class="d-flex mt-4 mb-6 ml-1" v-if="!isViewOnly">
        <v-progress-linear
          v-if="uploadProgress"
          v-model="uploadProgress"
          height="25"
        >
          <strong>{{ Math.ceil(uploadProgress) }}%</strong>
        </v-progress-linear>
        <v-btn
          v-else
          outlined
          color="primary"
          class="mr-2 mb-2"
          elevation="1"
          @click="$refs.dialogUpload.dialog = true"
        >
          {{ $_strings.contract.label.UPLOAD_CONTRACT }}
        </v-btn>
      </v-row>
      <v-data-table
        :headers="displayedContractHeader"
        :items="form.documents.filter((doc) => doc.documentType === 'CONTRACT' && !doc.isDeleted)"
        :items-per-page="100"
        hide-default-footer
      >
        <template v-slot:[`item.actions`]="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on" color="primary"
                dark
                icon
                @click="onButtonDownload(item)"
              >
                <v-icon>mdi-download</v-icon>
              </v-btn>
            </template>
            <span>{{ $_strings.contract.tooltips.DOWNLOAD }}</span>
          </v-tooltip>
          <v-tooltip bottom v-if="!isViewOnly">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                color="red"
                :loading="item.isLoading"
                dark
                icon
                @click="onDeleteItem(item)"
              >
                <v-icon>mdi-delete</v-icon>
              </v-btn>
            </template>
            <span>{{ $_strings.contract.tooltips.REMOVE }}</span>
          </v-tooltip>
        </template>
      </v-data-table>
    </v-card>
    <DialogContract
      ref="dialogUpload"
      @uploadFile="uploadFile"
    />
  </v-container>
</template>

<script>
import dayjs from 'dayjs';
import DialogContract from './Dialog/Upload.vue';

export default {
  props: {
    form: Object,
    isViewOnly: Boolean,
    newDocuments: Array,
  },
  components: {
    DialogContract,
  },
  mounted() {
    // GET LIST LOCATION ADENDUM
    if (this.path === 'addendum-contract') {
      this.fetchCityList(this.form.addendumLocation);
      if (this.form.addendumLocation) this.findCityList = true;
    }
  },
  watch: {
    'form.addendumLocation': function fetchLocation(newVal) {
      if (newVal && !this.findCityList) {
        this.fetchCityList(this.form.addendumLocation);
        this.findCityList = true;
      }
    },
  },
  data() {
    return {
      findCityList: false,
      uploadProgress: null,
      contractHeaders: [
        { text: this.$_strings.contract.header.DOCUMENT_NAME, value: 'documentName', sortable: false },
        { text: this.$_strings.contract.header.STATUS, value: 'documentStatus', sortable: false },
        { text: this.$_strings.contract.header.ACTION, value: 'actions', sortable: false },
      ],
      rules: {
        cityRule: [(v) => !!v || 'Tempat wajib diisi'],
        dateRule: [(v) => !!v || 'Tanggal wajib diisi'],
      },
      cityList: [],
      itemsCompanyList: [],
      isLoadingCityList: false,

      // DATE
      signedDateShow: false,
      effectiveDateShow: false,
    };
  },
  computed: {
    path() {
      // addendum-contract, contract
      const [,,, path] = this.$route.path.split('/');
      return path;
    },
    displayedContractHeader() {
      return this.contractHeaders.map((i) => ({
        ...i,
        class: 'white--text primary',
      }));
    },
  },
  methods: {
    dayjs,
    onButtonDownload(item) {
      this.$_services.uploads.downloadFile(item.url);
    },
    async fetchCityList(name = '') {
      const parameterLike = `${name}`;
      try {
        this.isLoadingCityList = true;
        const cities = await this.$_services.locations.cities(parameterLike, 0);
        this.cityList = [...this.cityList, ...cities.data.contents];
      } finally {
        this.isLoadingCityList = false;
      }
    },
    async uploadFile(formAttachment, encodedFile) {
      this.$refs.dialogUpload.dialog = false;
      try {
        const { companyId, contractNo } = this.form;
        const fileName = `contract_${dayjs().unix()}.${formAttachment.fileType}`;
        const filePath = `contract/${dayjs().get('year')}/${companyId}/${contractNo.split('/')[0]}/`;
        const self = this;
        const config = {
          onUploadProgress(progressEvent) {
            const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
            self.uploadProgress = percentCompleted;
          },
        };
        this.uploadProgress = 1;
        const result = await this.$_services.contract.uploadFile({ encodedFile, fileName, filePath }, config);
        const { url } = result.data;
        const data = {
          documentName: formAttachment.name,
          documentStatus: 'DRAFT',
          documentType: 'CONTRACT',
          url,
        };
        this.form.documents.push(data);
        this.newDocuments.push(data);
      } finally {
        this.uploadProgress = null;
      }
    },
    async onDeleteItem(item) {
      try {
        this.$set(item, 'isLoading', true);
        const { url } = item;
        const { id: workflowId } = this.$route.params;
        await this.$_services.contract.deleteDocumentContract(workflowId, url);
        this.form.documents.splice(this.form.documents.indexOf(item), 1);
        this.newDocuments.splice(this.newDocuments.indexOf(item), 1);
      } finally {
        this.$delete(item, 'isLoading');
      }
    },
  },
};
</script>

<style scoped lang="scss">
.theme--light.v-tabs-items {
  background-color: transparent;
}
.v-card.v-sheet.theme--light.elevation-5.rounded-0 {
  background-color: transparent;
}
</style>
